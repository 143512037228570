<template>
  <ion-page>
  <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content class="contact-card-content">
    <ion-row class="user-details-wrapper">
      <ion-col size="4">
      </ion-col>
      <ion-col class="user-info-wrapper" size="8">
        <h3 class="user-name">Claire Hale</h3>
        <h5 class="user-handle">@clairehale</h5>
      </ion-col>
      <ion-col class="user-stats-wrapper" size="6">
        <span class="user-stat-value">1553</span>
        <span class="user-stat-name">Following</span>
      </ion-col>
      <ion-col class="user-stats-wrapper" size="6">
        <span class="user-stat-value">537</span>
        <span class="user-stat-name">Followers</span>
      </ion-col>
      <ion-col size="12">
        <p class="user-bio">
          I am a product and visual designer based in Uruguay. I have designed at Google, Amazon and Microsoft.
        </p>
      </ion-col>
    </ion-row>
    <ion-row class="user-preferences-wrapper">
      <ion-col size="12">
        <h4 class="preference-name">Mobile</h4>
        <p class="preference-value">
          +1-202-555-0102
        </p>
      </ion-col>
      <ion-col size="12">
        <h4 class="preference-name">Email</h4>
        <p class="preference-value">
          clairhale@ionic.com
        </p>
      </ion-col>
      <ion-col size="12">
        <h4 class="preference-name">Address</h4>
        <p class="preference-value">
          921 Church St, San Francisco, CA
          <br/>
          94114, USA
        </p>
      </ion-col>
    </ion-row>
  </ion-content>
  </ion-page>
</template>

<script>
export default {
name: "Contact.vue"
}
</script>

<style scoped>


ion-header {
ion-toolbar {
  --background: transparent;
}
}
ion-content {
  position: absolute;
  top: 0;
  border-top: calc(var(--app-header-height) + var(--ion-safe-area-top));
  border-top-style: solid;
  border-top-color: var(--page-highlighted-background);
}

.contact-card-content {
  --background: var(--page-background);
transform: translateZ(0);

.user-details-wrapper {
  --ion-grid-column-padding: 0px;

  padding: 0px var(--page-margin) var(--page-margin);
  background-color: var(--page-highlighted-background);
  color: var(--ion-color-light);
  align-items: center;

.user-avatar {
  border: solid 3px var(--ion-color-light);
}

.user-info-wrapper {
  padding-left: calc(var(--page-margin) / 2);

.user-name {
  margin: 0px 0px 5px;
}

.user-handle {
  margin: 0px;
  font-weight: 400;
}
}

.user-stats-wrapper {
  text-align: center;
  padding-top: calc(var(--page-margin) / 2);

.user-stat-value {
  margin-right: 5px;
  font-weight: 500;
  font-size: 18px;
}

.user-stat-name {
  font-size: 16px;
}
}

.user-bio {
  margin: var(--page-margin) 0px 0px;
  line-height: 1.2;
  font-size: 14px;
}
}

.user-preferences-wrapper {
  --ion-grid-column-padding: 0px;

  padding: var(--page-margin);

.preference-name {
  margin: 0px 0px 5px;
  font-size: 16px;
}

.preference-value {
  margin: 0px 0px calc(var(--page-margin) / 2);
  font-size: 14px;
  line-height: 1.4;
  color: var(--ion-color-dark-tint);
}
}
}

</style>
